@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

/* @import url("http://localhost:3000/fonts/agrochip.ttf");
@font-face {
    font-family: "agrochip";
    src: url("http://localhost:3000/fonts/agrochip.ttf") format("truetype");
} */

@import url("https://painel.agrochip.agr.br/fonts/agrochip.ttf");
@font-face {
    font-family: "agrochip";
    src: url("https://painel.agrochip.agr.br/fonts/agrochip.ttf")
        format("truetype");
}

* {
    padding: 0;
    margin: 0;
    vertical-align: baseline;
    list-style: none;
    border: 0;
}
.fazenda-controller-popover {
    display: none;
}
.talhao-controller-popover {
    display: none;
}
.equipamento-controller-popover {
    display: none;
}
.tipoOp-controller {
    display: none;
}
.cultura-controller {
    display: none;
}
.safra-controller {
    display: none;
}
.operador-controller {
    display: none;
}
.fazenda-controller {
    padding: 4px;
}
.equipamento-controller {
    padding: 4px;
}
.talhao-controller {
    padding: 4px;
}
.operador-controller-popover .ant-form-item-no-colon span {
    padding-left: 2px !important;
}
.tempo-controller-popover {
    display: none;
}

@media (max-width: 921px) {
    .fazenda-controller {
        display: none;
    }
    .fazenda-controller-popover {
        display: block;
        margin-top: 15px;
        margin-bottom: -15px;
    }
    .fazenda-controller-popover .ant-form-item-no-colon span {
        padding-left: 2px !important;
        font-size: 14px !important;
    }
}
@media (max-width: 1066px) {
    .talhao-controller {
        display: none;
    }
    .talhao-controller-popover {
        display: block;
        margin-top: 15px;
    }
    .talhao-controller-popover .ant-select {
        width: 200px !important;
    }
    .talhao-controller-popover .ant-form-item-no-colon {
        margin-left: 5px;
    }
    .talhao-controller-popover .ant-form-item-no-colon span {
        padding-left: 2px !important;
        font-size: 14px !important;
    }
}
@media (max-width: 1320px) {
    .equipamento-controller {
        display: none;
    }
    .equipamento-controller-popover {
        display: block;
        margin-top: 15px;
    }
    .equipamento-controller-popover .ant-form-item-no-colon span {
        padding-left: 2px !important;
        font-size: 14px !important;
    }
}
@media (min-width: 1547px) {
    .tipoOp-controller {
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }
    .tipoOp-controller-popover {
        display: none;
    }
}
@media (min-width: 1797px) {
    .cultura-controller {
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }
    .cultura-controller-popover {
        display: none;
    }
}
@media (min-width: 2035px) {
    .safra-controller {
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }
    .safra-controller-popover {
        display: none;
    }
    .btn-filtroController {
        display: none !important;
    }
}
@media (min-width: 2035px) {
    .operador-controller {
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }
    .operador-controller-popover {
        display: none;
    }
    .btn-filtroController {
        display: none !important;
    }
}

@media screen and (max-width: 920px) {
    .talhao-controller-popover {
        display: none;
    }
    .operador-controller-popover {
        display: none;
    }
    .safra-controller-popover {
        display: none;
    }
    .cultura-controller-popover {
        display: none;
    }
    .tipoOp-controller-popover {
        display: none;
    }
    .equipamento-controller-popover {
        display: none;
    }
}

@media screen and (max-width: 920px) {
    .fazenda-controller-popover {
        padding: 2px !important;
    }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
    .tempo-controller {
        display: none;
    }
    .tempo-controller-popover {
        margin-top: 15px;
        display: block;
    }
}
.icon-filtros {
    padding-left: 10px;
    font-size: 18px;
    color: #606060;
}
.icon-filtros-dark {
    padding-left: 10px;
    font-size: 18px;
    color: #fff;
}

body {
    font-family: "Nunito", sans-serif !important;
}

.ant-modal-content {
    overflow: auto;
    border-radius: 5px !important;
}

.select-expand-ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 310px;
    border: 1px solid #d9d9d9;
    overflow: auto;
}
.select-expand-ul-dark {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 310px;
    border: 1px solid #6f6c6c;
    overflow: auto;
}

.select-expand-ul::-webkit-scrollbar {
    width: 15px;
}

.select-expand-ul::-webkit-scrollbar-track {
    background: #fff;
}

.select-expand-ul::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 4px solid #fff;
}

.select-expand-li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: default;
    z-index: 2;
    border-bottom: 1px solid #d9d9d9;
}
.select-expand-li-dark {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: default;
    z-index: 2;
    border-bottom: 1px solid #6f6c6c;
}

.select-expand-ul.dark .select-expand-li {
    border-bottom: 1px solid #434343;
}

.select-expand-li.active {
    background-color: rgba(47, 53, 126, 1);
    color: #fff;
}
.select-expand-li-dark.active {
    background-color: rgba(47, 53, 126, 1);
    color: #fff;
}

.icon-select.ant-select-dropdown {
    width: 150px !important;
}

.icon-select .icon-picker-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1 !important;
    width: 40px;
    height: 40px;
    font-size: 23px !important;
}

.icon-select .rc-virtual-list-holder-inner {
    flex-direction: row !important;
    flex-wrap: wrap !important;
}

.icon-select .ant-select-item-option,
.ant-select-item-option:hover {
    background-image: #fff !important;
}

.icon-select .ant-select-item-option-selected {
    background-image: #fff !important;
}

.icon-select .ant-select-item-option-active {
    background-image: #fff !important;
}

.toggle-wrapper {
    /*margin: 0.5em auto;*/
    margin-bottom: -15px;
    margin-top: -16px;
}

.toggle-wrapper label {
    padding: 0.3em;
    font-size: 17px;
    display: block;
}

.toggleContainer {
    border-radius: 2px;
    width: 100%;
    position: relative;
    background: #ffffff;
    border: 1px solid #d9d9d9;
}

.toggleContainer.list {
    background: none;
    border: none;
}

.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    color: #000;
    font-size: 12px;
    border-radius: 2px;
}

.select-expand-li.active .toggleContainer.list .toggleOption {
    color: #fff;
}

.toggleContainer .toggleOption.selected {
    color: #ffffff;
    cursor: initial;
}

.toggleContainer .toggle {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    transition: all 0.4s ease-in-out;
    border-radius: 2px;
    background: #1d214d;
}

.optionDisabled {
    background: #e6e6e6;
    cursor: no-drop !important;
}

.toggleContainer .ativo {
    background-color: #12eb02;
}
.toggleContainer .inativo {
    background-color: #de0000;
}
.toggleContainer .manutencao {
    background-color: #ebeb00;
}

.toggle-dashboard {
    width: 100px;
    height: 26px !important;
    font-size: 10px;
    margin-top: 6px;
    margin-left: 8px;
    margin-right: 10px;
}

.toggle-dashboard .fa-map-marked-alt,
.toggle-dashboard .fa-chart-bar {
    font-size: 18px;
}

.toggle-dashboard .toggleOption {
    height: 26px;
}

.toggle-dashboard > .toggleContainer {
    height: 26px !important;
}

.toggle-dashboard .mapa {
    background-color: rgba(47, 53, 126, 0.8);
}
.toggle-dashboard .dashboard {
    background-color: rgba(47, 53, 126, 0.8);
}

.select-expand-li .status {
    font-size: 12px;
}

.select-expand-li .ativo {
    background-color: #12eb02;
    color: #000;
    border-radius: 2px;
    padding: 5px 10px 5px 10px;
}

.select-expand-li .inativo {
    background-color: #de0000;
    color: #fff;
    border-radius: 2px;
    padding: 5px 10px 5px 10px;
}

.select-expand-li .manutencao {
    background-color: #ebeb00;
    color: #000;
    border-radius: 2px;
    padding: 5px 10px 5px 10px;
}

.equipamento-side .ant-select-item-option {
    background-color: #000 !important;
    color: #fff !important;
    border-bottom: 1px solid #ccc !important;
}

.equipamento-side .ant-select-item-option:hover {
    background-color: #111 !important;
}

.antena-popover {
    display: flex;
    flex-direction: column;
    width: 160px;
}

.antena-popover-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px 0 10px 0;
    cursor: pointer;
}

.antena-popover-item:hover {
    text-decoration: underline;
}

.antena-popover .antena-popover-item:last-child {
    border: 0;
}
.css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
    font-family: "Nunito", sans-serif !important;
    font-weight: normal !important;
    margin: 0px !important;
}
.ant-table-content {
    font-size: 14px;
    font-family: "Nunito", sans-serif;
    white-space: nowrap;
}

.ant-table-thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fafafa;
}
div.ant-input-textarea.ant-input-textarea-show-count::after {
    position: absolute;
    margin-top: 5px;
    margin-left: -50px;
    font-weight: lighter;
}

/* Estilos para o Dashboard */

ul.menu-dash-padrao {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    text-align: right;
}

ul.menu-dash-padrao li {
    border-bottom: 1px solid #c1c1c1;
    transition: border 0.3s linear, color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    padding: 7px;
    color: #757575;
}

ul.menu-dash-padrao li:hover {
    border-bottom: 1px solid #3b3b3b;
    transition: border 0.3s ease-in-out, color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    color: rgb(28, 28, 28);
    cursor: pointer;
    /* background-color: rgb(237, 237, 237); */
}

/* //////////////////////// */

.agrochip-dropdown-equipa {
    width: 300px !important;
}

.agrochip-dropdown .ant-select-item-option-selected {
    background-color: rgba(47, 53, 126, 0.8) !important;
    color: #fff !important;
}

.gm-ui-hover-effect {
    display: none !important;
}

.MuiToggleButtonGroup-root {
    margin-top: 10px;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
    color: #fff !important;
    border: 0px !important;
    border-radius: 0px !important;
}

.MuiToggleButton-root.Mui-selected {
    background: #fff !important;
    color: rgba(47, 53, 126, 0.8) !important;
}

.rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default.rs-btn-sm {
    height: 26px !important;
    border-radius: 0 !important;
    border: 1px solid rgb(193, 193, 193);
    background: transparent !important;
}

.rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-sm {
    height: 26px !important;
    border-radius: 0 !important;
    border: 1px solid rgb(193, 193, 193);
    background: transparent !important;
}

.rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-sm:hover {
    height: 26px !important;
    border-radius: 0 !important;
    border: 1px solid rgb(193, 193, 193);
    background: transparent !important;
}

.rs-picker-toggle-value {
    color: #606060 !important;
}

.scroll-sidebar::-webkit-scrollbar {
    width: 15px;
}

.scroll-sidebar::-webkit-scrollbar-track {
    background: #000;
}

.scroll-sidebar::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
    border: 4px solid #000;
}

.equipa-toggle.toggleContainer {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: #606060;
    margin-top: 20px;
}

.equipa-toggle .historico {
    background-color: #434343;
    color: #fff;
}
.equipa-toggle .ultimo {
    background-color: #434343;
    color: #fff;
}

.equipa-toggle.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
}
.equipa-toggle-disabled.toggleContainer {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: #606060;
    margin-top: 20px;
}

.equipa-toggle-disabled .historico {
    background-color: #434343;
    color: #757575;
}
.equipa-toggle-disabled .ultimo {
    background-color: #434343;
    color: #fff;
}

.equipa-toggle-disabled.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
}
.equipa-toggle-disabled.toggleContainer .toggleOption:not(.selected) {
    color: #757575 !important;
}

/* toggle grafico dash equipamento */
.graf-toggle.toggleContainer {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: #606060;
    margin-top: 20px;
}

.graf-toggle .situacao {
    background-color: #606060;
    color: #fff;
}

.graf-toggle .parada {
    background-color: #606060;
    color: #fff;
}

.graf-toggle.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
}

.graf-toggle-disabled.toggleContainer {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: #606060;
    margin-top: 20px;
}

.graf-toggle-disabled .parada {
    background-color: #434343;
    color: #757575;
}

.graf-toggle-disabled .situacao {
    background-color: #434343;
    color: #fff;
}

.graf-toggle-disabled.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
    border-radius: 2px;
    color: #fff;
}

.graf-toggle-disabled.toggleContainer .toggleOption:not(.selected) {
    color: #757575 !important;
}

/* light tema */
.graf-toggle-light.toggleContainer {
    background-color: rgba(255, 255, 255, 0.7);
    /* border-color: #606060; */
    margin-top: 20px;
}
.graf-toggle-light .situacao {
    background-color: #b5b5b5;
    color: #fff;
}
.graf-toggle-light .parada {
    background-color: #b5b5b5;
    color: #fff;
}
.graf-toggle-light.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
    border-radius: 2px;
    color: #000;
}
.graf-toggle-light-disabled.toggleContainer {
    background-color: rgba(255, 255, 255, 0.7);
    /* border-color: #606060; */
    margin-top: 20px;
}
.graf-toggle-light-disabled .parada {
    background-color: #b5b5b5;
    color: #757575;
}
.graf-toggle-light-disabled .situacao {
    background-color: #b5b5b5;
    color: #fff;
}
.graf-toggle-light-disabled.toggleContainer .toggleOption {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 16px;
    border-radius: 2px;
    color: #000;
}
.graf-toggle-light-disabled.toggleContainer .toggleOption:not(.selected) {
    color: #b3b3b3 !important;
}

.relatorio-resumo-zebrado > div:nth-child(4n - 1),
.relatorio-resumo-zebrado > div:nth-child(4n) {
    background-color: #e9e9e9;
}
.relatorio-resumo-zebrado-dark > div:nth-child(4n - 1),
.relatorio-resumo-zebrado-dark > div:nth-child(4n) {
    background-color: #595959;
}

.ant-row .ant-form-item.form_item_faz {
    flex-direction: column;
}

.ant-row .ant-form-item.form_item_faz > .ant-col.ant-form-item-label {
    text-align: left;
}

.tutorial-mask {
    background: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.tutorial-helper [data-tour-elem="badge"] {
    background: rgba(47, 53, 126, 1);
}

.tutorial-helper [data-tour-elem="dot"].reactour__dot--is-active {
    background-color: rgba(47, 53, 126, 1);
}

.tabela-operacoes {
    width: calc(100vw - 50px);

    /*height: 100vh;*/
}
.tabela-operacoes thead th {
    margin: 2px;
    padding: 8px 15px;
    box-shadow: inset 0 0 1px #fff;
    color: #d1d1d1;
}

.tabela-operacoes td {
    padding: 8px 15px;
    border: 1px solid #ccc;
}
.tabela-operacoes tr {
    white-space: nowrap;
}
.tabela-operacoes tbody {
    overflow: auto;
}
.tabela-operacoes thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #6e6e6e;
}
.tabela-operacoes tbody tr:nth-child(odd) {
    background: #f2f2f2;
}
.tabela-operacoes-dark {
    width: calc(100vw - 50px);

    /*height: 100vh;*/
}
.tabela-operacoes-dark thead th {
    margin: 2px;
    padding: 8px 15px;
    box-shadow: inset 0 0 1px #424242;
    color: #d9d9d9;
}

.tabela-operacoes-dark td {
    padding: 8px 15px;
    border: 1px solid #424242;
}
.tabela-operacoes-dark tr {
    white-space: nowrap;
}
.tabela-operacoes-dark tbody {
    overflow: auto;
}
.tabela-operacoes-dark thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #6e6e6e;
}
.tabela-operacoes-dark tbody tr:nth-child(odd) {
    background: #f2f2f2;
}
.tabela-operacoes-dark tbody tr:nth-child(odd) {
    background: #3d3d3d;
}

.opera-tab.ant-tabs .ant-tabs-nav-list > .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #6e6e6e;
    z-index: 1058;
    border-bottom: 1px solid #6e6e6e;
}
.opera-tab.ant-tabs
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active
    > .ant-tabs-tab-btn {
    color: #d1d1d1;
}

.server-antena {
    margin-left: 10px;
    cursor: pointer;
    fill: #1c462e;
}
.server-antena.dark {
    fill: #ffffff;
}

.server-antena:hover * {
    fill: #337d53;
}

/* .server-antena path:hover,
.server-antena text:hover,
.server-antena ellipse:hover {
    fill: #337d53;
} */

.label-talhao {
    color: #fff;
    text-shadow: 2px 2px 2px #000;
}

.ant-message {
    z-index: 3000 !important;
}

.dark-calendario {
    background-color: #757575;
}
.dark-calendario .rs-picker-toggle > .rs-picker-toggle-value {
    color: #fff !important;
}
.dark-calendario .rs-picker-toggle > .rs-picker-toggle-textbox {
    background-color: #303030 !important;
    color: #fff !important;
}

.rs-picker-menu {
    z-index: 1030 !important;
}

@media (max-width: 991.98px) {
    .rs-picker-daterange-header {
        display: none;
    }
    @media screen and (orientation: landscape) {
        .rs-picker-daterange-panel-show-one-calendar {
            display: flex;
            max-height: 260px;
        }
        .rs-picker-toolbar {
            border-top: none !important;
        }
    }
    .rs-picker-toggle-textbox {
        display: none;
    }
}

.one-dark-calendario {
    background-color: #757575;
}
.one-dark-calendario .rs-picker-toggle > .rs-picker-toggle-value {
    color: #fff !important;
}
.one-dark-calendario .rs-picker-toggle > .rs-picker-toggle-textbox {
    background-color: #303030 !important;
    color: #fff !important;
}
.one-dark-calendario .rs-icon {
    color: #fff !important;
}

body[data-theme="dark"] .rs-picker-daterange-panel {
    background-color: #303030 !important;
}
body[data-theme="dark"]
    .rs-picker-daterange-panel
    .rs-picker-daterange-content
    .rs-picker-daterange-calendar-group
    .rs-calendar {
    border-right: 1px solid #424242;
}
body[data-theme="dark"]
    .rs-picker-daterange-panel
    .rs-picker-daterange-content
    .rs-picker-daterange-header {
    border-bottom: 1px solid #424242;
}
body[data-theme="dark"] .rs-picker-daterange-panel .rs-picker-toolbar {
    border-top: 1px solid #424242;
}
.rs-icon {
    font-size: 14px;
}
.dark-calendario .rs-icon {
    color: #fff !important;
}
.gm-style-iw-d {
    color: #000;
}

@media screen and (max-width: 920px) {
    .gm-style-iw {
        max-width: 280px !important;
    }
}

.phone-dark {
    width: 100%;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.85);
    border: 1px solid #6f6c6c;
    border-radius: 4px;
    padding: 4px 11px;
    transition: all 0.3s;
    font-size: 14px;
}
.phone-dark:hover {
    border-color: #116d99 !important;
    border-right-width: 1px !important;
}
.phone-dark:focus {
    border-color: #008ed1;
    box-shadow: 0 0 0 2px rgb(0 142 209 / 20%);
    border-right-width: 1px !important;
    outline: 0;
}
.phone {
    width: 100%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
    transition: all 0.3s;
    font-size: 14px;
}
.phone:hover {
    border-color: #23a9de !important;
    border-right-width: 1px !important;
}
.phone:focus {
    border-color: #008ed1;
    box-shadow: 0 0 0 2px rgb(0 142 209 / 20%);
    border-right-width: 1px !important;
    outline: 0;
}
.phone::placeholder {
    color: #c5c6c7 !important;
}

.ant-progress-bg {
    height: 15px !important;
}
.ant-progress-inner {
    border-radius: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.info-alerta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 4px;
    gap: 2px;
}

.info-alerta .titulo {
    font-weight: bold;
    color: #000;
}

.ant-picker-time-panel .ant-picker-content {
    height: 150px !important;
}

.table-exp .ant-table-row {
    /* background: red !important; */
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
}
.table-exp td {
    width: 50%;
}
.table-exp td:not(:first-child) {
    width: 50%;
    display: flex;
    /* background: red !important; */
    padding-left: 22px !important;
    /* justify-content: center !important; */
}

.tabela-operacional {
}
.tabela-operacional thead th {
    margin: 2px;
    padding: 8px 15px;
    box-shadow: inset 0 0 1px #fff;
    color: #d1d1d1;
}

.tabela-operacional td {
    padding: 8px 15px;
    border: 1px solid #ccc;
}
.tabela-operacional tr {
    white-space: nowrap;
}
.tabela-operacional tbody {
    overflow: auto;
}
.tabela-operacional thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgba(47, 53, 126, 1);
}
.tabela-operacional tbody tr:nth-child(odd) {
    background: #f2f2f2;
}
.tabela-operacional-dark {
}
.tabela-operacional-dark thead th {
    margin: 2px;
    padding: 8px 15px;
    box-shadow: inset 0 0 1px #424242;
    color: #d9d9d9;
}

.tabela-operacional-dark td {
    padding: 8px 15px;
    border: 1px solid #424242;
}
.tabela-operacional-dark tr {
    white-space: nowrap;
}
.tabela-operacional-dark tbody {
    overflow: auto;
}
.tabela-operacional-dark thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgba(47, 53, 126, 1);
}
.tabela-operacional-dark tbody tr:nth-child(odd) {
    background: #f2f2f2;
}
.tabela-operacional-dark tbody tr:nth-child(odd) {
    background: #3d3d3d;
}

.ant-popover-placement-bottomLeft {
    left: 10px !important;
}

.tour-user .ant-popover-arrow {
    display: none !important;
}

.extra-itens .ant-popover-arrow {
    display: none !important;
}

.ant-skeleton {
    height: 25px !important;
    max-width: 50px;
}
.ant-skeleton-title {
    margin-top: 4px !important;
    margin-bottom: 0px;
    margin-left: 2px;
}
